import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button } from '@naf/button';
import { StepWizardChildProps } from 'react-step-wizard';
import { useStateMachine } from 'little-state-machine';
import { Input } from '@naf/input';
import { useDispatch } from 'react-redux';
import { GridCol, GridRow } from '@naf/grid';
import { Error } from '@styled-icons/material/Error';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { WizardNavigation } from '../../components/WizardNavigation';
import { updateAction } from '../../lib/updateAction';
import useSelector from '../../../../redux/typedHooks';
import { Spinner } from '../../../../components/spinner/Spinner';
import { ProductSelect } from '../../fieldInputs/Product';
import S from './Styles';
import { SummaryProvider } from '../../components/Summary';
import { useQueryParams } from '../../../../hooks/useQueryParameters';
import { updateUrlQueryValues } from '../../../../utils/updateUrlQueryValues';
import { actionCreators } from '../../../../redux/modules/application';
import { actions as becomeAMemberActions } from '../../../../redux/modules/becomeAMember';
import { ProductIdSelect } from '../../fieldInputs/ProductId';
import { ProductID } from '../../../../lib/ProductID';
import { defaultProductOrder } from '../../defaultProductOrder';
import BlockContent from '../../../../components/block-content/BlockContent';
import SimpleTextLink from '../../../../components/simple-textlink/SimpleTextLink';
import { InfoMessageBox } from '../../../../components/block-content/components/types/components/InfoMessageBox/InfoMessageBox';

export interface ProductOrderType {
  productId: ProductID;
  amount: number;
  isHouseholdProduct: boolean;
}

// Define the form - receives props from wizard
export const Membership: FC<
  Partial<
    StepWizardChildProps & { setNumberOfMembers: (input: number) => void; setProductId: (input: ProductID) => void }
  >
> = ({ totalSteps, currentStep, nextStep, previousStep, setNumberOfMembers, setProductId }) => {
  // Get current form state from session storage
  const {
    actions,
    state: { membershipForm },
  } = useStateMachine({ updateAction });

  const [productOrder, setProductOrder] = useState<ProductOrderType>(defaultProductOrder);

  const updateOrder = useMemo(
    () => (newProductOrder: ProductOrderType) => {
      setProductOrder(newProductOrder);
      setProductId(newProductOrder?.productId);
      setNumberOfMembers(newProductOrder?.amount);
    },
    [setProductOrder, setProductId, setNumberOfMembers],
  );

  // Function submitting form data to local storage
  const onSubmit = (e) => {
    e.preventDefault();
    actions.updateAction({ formName: 'membershipForm', data: { product: productOrder } });
  };

  const [isMounted, setIsMounted] = useState(false);
  const [showInfoMessageBox, setShowInfoMessageBox] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // // Initiate a form context
  // const {
  //   register,
  //   watch,
  //   control,
  //   handleSubmit,
  //   unregister,
  //   setError,
  //   setFocus,
  //   clearErrors,
  //   setValue,
  //   trigger,
  //   formState,
  //   formState: { errors },
  //   reset,
  //   resetField,
  //   getValues,
  //   getFieldState,
  // } = useForm({
  //   resolver: yupResolver(MembershipFormSchema),
  // });

  const isUpdating = useSelector((state) => Object.values(state.becomeAMember.isUpdating)[0] || null);
  const campaignCodeDetails = useSelector((state) => state.becomeAMember?.data?.campaignCodeDetails);

  const queryParameters = useQueryParams();
  const dispatch = useDispatch();

  const products = useSelector((state) => state.becomeAMember.data.products || null);

  // Set the product from query parameters if present
  useEffect(() => {
    if (
      queryParameters?.product &&
      typeof queryParameters.product !== 'undefined' &&
      products[queryParameters.product]
    ) {
      updateOrder({ productId: queryParameters.product, amount: 1, isHouseholdProduct: true });
    } else if (membershipForm.product) {
      updateOrder(membershipForm.product);
    }
  }, [membershipForm, updateOrder, queryParameters?.product, products]);

  const [temporaryCampaignCode, setTemporaryCampaignCode] = useState<undefined | string>(queryParameters?.campaignCode);

  useEffect(() => {
    if (queryParameters?.campaignCode) {
      setTemporaryCampaignCode(queryParameters?.campaignCode);
    }
  }, [queryParameters?.campaignCode]);

  if (!products && isUpdating) return <Spinner />;

  return !isUpdating ? (
    <>
      <GridRow>
        <GridCol s="12" m="12" l="8" xl="8">
          <S.StyledForm onSubmit={onSubmit}>
            {[ProductID.Membership, ProductID.MembershipWithRoadsideAssistance, undefined].includes(
              queryParameters?.product,
            ) && (
              <>
                <S.Intro>
                  Et NAF-medlemskap gir deg tilgang til en rekke fordeler og et omfattende rabattprogram. Her kan du
                  velge vekk noen av disse fordelene om du ønsker et billigere medlemskap.{' '}
                  <S.TooltipWrapper>
                    <S.StyledTooltip text="Du går glipp av blant annet veihjelp, men får fortsatt bilteknisk rådgivning og rabatt på alle NAF-sentre." />
                  </S.TooltipWrapper>
                </S.Intro>
                <S.InlineLabel htmlFor="productIdSelection">
                  <ProductIdSelect
                    amount={productOrder?.amount || 1}
                    onChange={() => {
                      // this only supports two products, roadhelp and without roadhelp.
                      // if product query parameter differs from these two, remove option, keep product id constant
                      if (productOrder?.productId === ProductID.Membership) {
                        // https://github.com/orgs/react-hook-form/discussions/3715
                        updateOrder(defaultProductOrder);
                        setShowInfoMessageBox(false);
                      } else {
                        updateOrder({
                          productId: ProductID.Membership,
                          amount: 1,
                          isHouseholdProduct: true,
                        });
                        setShowInfoMessageBox(true);
                      }
                    }}
                    value={productOrder?.productId || ProductID.MembershipWithRoadsideAssistance}
                  />
                </S.InlineLabel>
                {showInfoMessageBox && (
                  <InfoMessageBox value={products[ProductID.Membership]?.productDescription?.confirmationMessageBox} />
                )}
              </>
            )}
            {products[productOrder?.productId]?.productDescription.body && (
              <BlockContent value={products[productOrder?.productId]?.productDescription.body || []} />
            )}
            <S.InlineLabel htmlFor="productField" $extraMargin={!campaignCodeDetails?.hasAvailableCampaignCodes}>
              <ProductSelect value={productOrder} updateOrder={updateOrder} />
            </S.InlineLabel>

            {campaignCodeDetails?.hasAvailableCampaignCodes ? (
              <S.Label htmlFor="campaignCode">
                <S.LabelTextWrapper $isHidden={campaignCodeDetails?.isValid}>
                  <S.LabelIcon $visible={typeof queryParameters?.campaignCode !== 'undefined'}>-</S.LabelIcon>
                  <S.LabelIcon $visible={typeof queryParameters?.campaignCode === 'undefined'}>+</S.LabelIcon>
                  <S.LabelText
                    onClick={() => {
                      if (typeof queryParameters?.campaignCode === 'undefined')
                        updateUrlQueryValues.updateCampaignCode('');
                      if (typeof queryParameters?.campaignCode !== 'undefined') {
                        updateUrlQueryValues.removeCampaignCode();
                        setTemporaryCampaignCode(undefined);
                        dispatch(becomeAMemberActions.getBecomeAMember.request(undefined));
                      }
                      dispatch(actionCreators.incrementUrlParamsIndex());
                    }}
                  >
                    Legg til kampanjekode
                  </S.LabelText>
                  <S.TooltipWrapper>
                    <S.StyledTooltip text="Har du mottatt en kampanjekode fra NAF kan du skrive inn den her. Kampanjekoder gir ikke rabatt på medlemskontingent." />
                  </S.TooltipWrapper>
                </S.LabelTextWrapper>
                <S.CampaignCode $visible={typeof queryParameters?.campaignCode !== 'undefined'}>
                  <S.InputWrapper $isHidden={campaignCodeDetails?.isValid}>
                    <Input
                      error={campaignCodeDetails?.isValid === false}
                      onChange={(e: string) => {
                        setTemporaryCampaignCode(e);
                      }}
                      value={temporaryCampaignCode || queryParameters?.campaignCode}
                      placeholder="Kampanjekode"
                    />
                    <Button
                      disabled={campaignCodeDetails?.isValid === true}
                      onClick={() => {
                        updateUrlQueryValues.updateCampaignCode(temporaryCampaignCode);
                        dispatch(becomeAMemberActions.getBecomeAMember.request(temporaryCampaignCode));
                      }}
                    >
                      {campaignCodeDetails?.isValid === true ? 'Lagt til' : 'Legg til'}
                    </Button>
                  </S.InputWrapper>
                  {campaignCodeDetails?.isValid === true && (
                    <S.CampaignCodeMessageWrapper>
                      <CheckCircle size={24} />
                      <div>
                        <S.CampaignCodeMessageText>
                          Kampanjekoden er lagt til ({campaignCodeDetails?.campaignCode})
                        </S.CampaignCodeMessageText>
                        <S.CampaignCodeMessageText>{campaignCodeDetails?.description}</S.CampaignCodeMessageText>
                        <SimpleTextLink
                          onClick={() => {
                            updateUrlQueryValues.removeCampaignCode();
                            setTemporaryCampaignCode(undefined);
                            dispatch(becomeAMemberActions.getBecomeAMember.request(undefined));
                            dispatch(actionCreators.incrementUrlParamsIndex());
                          }}
                        >
                          Fjern kode
                        </SimpleTextLink>
                      </div>
                    </S.CampaignCodeMessageWrapper>
                  )}
                  {campaignCodeDetails?.isValid === false && (
                    <S.CampaignCodeMessageWrapper $isErrored>
                      <Error size={24} />
                      <S.CampaignCodeMessageText>Kampanjekoden er ikke gyldig!</S.CampaignCodeMessageText>
                    </S.CampaignCodeMessageWrapper>
                  )}
                </S.CampaignCode>
              </S.Label>
            ) : null}
          </S.StyledForm>
        </GridCol>
        {isMounted && <SummaryProvider productOrder={productOrder} />}
      </GridRow>
      <GridRow>
        <GridCol s="12" m="12" l="6" xl="6">
          <WizardNavigation
            totalSteps={totalSteps}
            currentStep={currentStep}
            nextStep={nextStep}
            previousStep={previousStep}
            isValid={Object.values(products).length > 0}
            onSubmit={onSubmit}
          />
        </GridCol>
      </GridRow>
    </>
  ) : (
    <Spinner />
  );
};
